/* Remove default margins/padding on html/body */
html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #2b2b2b;
  font-family: 'Lato';
  font-weight: 600;
}

/* A hero container that fills the entire viewport */
.hero {
  width: 100%;
  height: 100vh; /* full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: top;
  padding-top: 140px;
  padding-left: 100px;
}

.hero-title {
  color: #f09c35;
  font-size: 71px;
  margin-bottom: 0.5rem;
}
.hero-subtitle {
  color: #f6f7ef;
  font-size: 31px;
  margin-bottom: 2rem;
}
.hero-icons {
  display: flex;
  color: #f6f7ef;
  gap: 1rem;
}
.hero-icons img,
.hero-icons ion-icon {
  font-size: 32px;
  color: #f6f7ef;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.hero-icons ion-icon:hover,
.hero-icons img:hover {
  transform: scale(1.1);
  transition: transform 0.2s;
}
/* For smaller screens */
@media (max-width: 768px) {
  .hero {
    padding-top: 80px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .hero-title {
    font-size: 36px;
  }
  .hero-subtitle {
    font-size: 20px;
  }
}

/* For medium-sized screens (like tablets), adjust padding slightly */
@media (max-width: 1024px) {
  .hero {
    padding-top: 120px;
    padding-left: 50px;
  }
}
